@import '../../styles.module.scss';

.marker {
  position: relative;

  .center {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(-50%, -50%, 0);
  }

  label {
    @extend .center;
    @extend .clickable;
    font-size: 24px;
    font-weight: 500;
    text-shadow:
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
  }

  .point {
    @extend .center;
    @extend .clickable;
    display: none;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: currentColor;
  }

  .tooltip {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(-50%, -12px, 0);
    display: flex;
    flex-direction: column;
    $bg: white;

    .close {
      @extend .clickable;
      z-index: 3;
      align-self: flex-end;
      margin-bottom: -0.5rem;
      margin-right: -0.5rem;
      background-color: white;
      color: $colorDefault;
      width: 0.8rem;
      height: 0.8rem;
      padding: 0.2rem;
      border-radius: 50%;
      box-shadow: 0 0 0.5rem rgba(black, 0.5);
    }

    .container {
      position: relative;
      background-color: $bg;
      border-radius: 0.5rem;
      padding: 0.5rem;
      min-width: 2rem;
      min-height: 2rem;

      .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
      }

      img {
        position: relative;
        max-width: 15rem;
        max-height: 15rem;
        display: block;
        z-index: 3;
      }
    }

    .pin {
      border: 10px solid transparent;
      align-self: center;
      border-bottom: none;
      border-top-color: $bg;
    }
  }

  .windDir {
    display: none;
  }

  &.wind {

    .wdShown {
      z-index: 5;
      font-size: 1rem;
      font-weight: bold;
      text-shadow: unset;
      display: unset;
    }

    label {
      &.wdShown {
        @extend .wdShown;
      }
    }

    .windDir {
      @extend .center;
      z-index: 2;

      &.shown {
        display: unset;
      }
    }

    &.clustered {
      &:hover {
        label {
          @extend .wdShown;
          z-index: 5;
        }

        .windDir {
          display: unset;
        }
      }
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &.clustered {
    label {
      display: none;
    }

    .point {
      display: unset;
    }

    &:hover {
      label {
        display: unset;
        animation: show 0.2s ease-in-out;
      }

      .point {
        display: none;
      }
    }
  }

  .photo {
    cursor: pointer;
  }
}
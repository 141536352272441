@import '../../styles.module.scss';

.slider {
  position: relative;
  padding-top: 10px;
  margin-left: -4px;
  width: calc(100% + 8px);

  :global(.ant-slider-rail) {
    background-color: #6c757d;
  }

  :global(.ant-slider-track) {
    background-color: $colorPrimary;
  }

  :global(.ant-slider-rail),
  :global(.ant-slider-track) {
    height: 10px;
    border-radius: 4px;
  }

  :global(.ant-slider-handle) {
    margin-left: -5px;
    margin-top: -14px;
    z-index: 5;
    transform: rotate(45deg) !important;
    box-shadow: 0 0 0.5rem rgba(black, 0.9);

    &::before,
    &::after {
      border-bottom-right-radius: 0 !important;
    }

    &::after {
      box-shadow: 0 0 0 4px $colorPrimary;
    }
  }

  .markers {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 8px);
    padding: 0 4px;

    > * {
      width: 2px;
      height: 4px;
      border: 1px solid white;
      border-radius: 50%;
      background-color: white;
    }

    > :first-child,
    > :last-child {
      opacity: 0;
    }
  }
}

@import '../../styles.module.scss';

$pd: 1rem;
$colorHKO: #030032;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.2rem;
  border: $colorHKO 1px solid;
  overflow: hidden;
  margin: calc(#{$pd} / 2);
  background-color: $colorBg;
  padding: $pd;
  padding-top: calc(2 * #{$pd});
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
}

.page {
  display: flex;
  flex-direction: column;
  background-color: $colorBgDark;
  padding: $pd;
  height: calc(100vh - 2 * #{$pd});
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    margin-top: calc(-1 * #{$pd});
    margin-left: calc(-1 * #{$pd} - 0.2rem);

    $trapezoidPadding: 7rem;

    .trapezoid {
      clip-path: polygon(0% 0%, 100% 0%, calc(100% - #{$trapezoidPadding}) 100%, 0% 100%);
    }

    >:nth-child(1),
    >:nth-child(2) {
      @extend .trapezoid;
      height: 6rem;
      border: 1px solid $colorHKO;
    }

    .logo {
      background-color: $colorBg;
      padding: 1rem 2rem;
      padding-right: $trapezoidPadding;
      z-index: 2;

      img {
        height: 6rem;
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      background-color: $colorHKO;
      color: $colorBg;
      padding: 1rem $trapezoidPadding;
      margin-left: calc(-1 * #{$trapezoidPadding});
      justify-content: space-around;

      .title {
        font-size: 0.8rem;
      }

      .station {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

  }

  .updateTime {
    display: flex;
    align-items: baseline;
    margin-left: 1rem;
    margin-top: 0.2rem;

    >:first-child {
      display: flex;
      font-size: 0.8rem;
      flex-direction: column;
      margin-right: 1rem;
    }

    >:last-child {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  h2 {
    clip-path: polygon(0% 0%, 100% 0%, calc(100% - 1.5rem) 100%, 0% 100%);
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    margin-top: -0.1rem;
    margin-left: -0.1rem;
    padding: 0.25rem 2.5rem 0.25rem 1rem;
    font-size: 1rem;
    background-color: $colorHKO;
    color: $colorBg;
    border-radius: 0 0 0.5rem 0;
    opacity: 0.9;
    z-index: 3;
    white-space: nowrap;
    text-align: start;
    line-height: 1.25rem;
  }

  .upper {
    display: flex;
    flex-direction: column;

    .latestObs {
      display: flex;

      .obs {
        @extend .card;
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;

        .icon {
          font-size: 1.25rem;
          margin-top: 0.25rem;
          margin-right: 0.5rem;
          opacity: 0.8;
        }

        .title {
          font-size: 1rem;
          opacity: 0.8;
        }

        .value {
          font-size: 2rem;
          font-weight: bold;

          .unit {
            margin-left: 0.5rem;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .below {
    display: flex;
    flex: 1;

    .charts {
      flex: 2;
      display: flex;
      flex-wrap: wrap;

      .chart {
        @extend .card;
        width: calc(50% - #{$pd} * 3 - 6px);

        .content {
          margin-top: 0rem;
        }
      }
    }

    .graphics {
      flex: 1;
      display: flex;
      flex-direction: column;

      .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      .image {
        @extend .card;
        padding: unset;
        position: relative;
        min-height: 30vh;

        img {
          position: relative;
          display: block;
          overflow: hidden;
          max-height: calc(100vh - 35rem);
          object-fit: cover;
          z-index: 2;
        }
      }

      .map {
        @extend .card;
        padding: unset;
        flex: 1;

        h2 {
          z-index: 999;
        }

        .icon {
          font-size: 2rem;
        }
      }
    }
  }
}
@import '../styles.module.scss';

.chart {
  position: relative;
  width: 100%;

  @keyframes showUp {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0.7;
    }

    60% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }

  .unit {
    position: absolute;
    top: 0rem;
    left: 2.5rem;
    transform: translateX(-50%);
    font-size: 1rem;
  }

  .scrollHint {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    opacity: 0;
    background-color: #222;
    color: white;
    border-radius: 6rem;
    padding: 0.5rem 1rem;
  }

  &.scrollable {
    &:hover {
      .scrollHint {
        animation: showUp 2s;
      }
    }
  }

  .chartTooltips {
    background-color: rgba($colorBgDark, 0.95);
    border: 1px solid $colorBorder;
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1rem;

    .time {
      div {
        display: inline-block;
        width: 1rem;
      }
    }

    .value {
      font-weight: bold;
    }
  }
}

.xLabel {
  color: $colorDefault;
  transform: translateY(1.5rem);
  font-size: 1rem;
}

.yLabel {
  color: $colorDefault;
  transform-origin: 0% 50%;
  transform: rotate(-90deg) translateY(2.2rem) translateX(2rem);
  text-anchor: end;
  font-size: 1rem;
}

:global(.recharts-line-dots) {
  clip-path: none !important;
}

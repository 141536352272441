@import '../../styles.module.scss';

.otp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0rem;

  .description {
    text-align: justify;
    color: $colorDefault;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .resend {
    margin-top: 0.5rem;
    color: $colorBorder;
    font-size: 0.8rem;
    margin-bottom: -1.75rem;
    cursor: pointer;

    &.active {
      color: $colorPrimary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
$colorBg: #f5f5f3;
$colorBgDark: #e1e1e7;
$colorBgLight: #f9f9f9;
$colorDefault: #1d1c1a;
$colorBorder: #bfbfb970;
$colorLight: #bbb;
$colorDanger: #d90021;
$colorPrimary: #4285f4;
$colorOK: #3ab600;
$colorWarning: #f94a29;

:global(.ant-input-affix-wrapper),
:global(.ant-select-selector),
:global(.ant-select-dropdown),
:global(.ant-btn) {
  border-radius: 0.3rem !important;
}

:global(.ant-select-item-option-content) {
  font-size: 1rem;
}

:global(.ant-tooltip-content) {
  font-size: 0.8rem;
}

.clickable {
  &,
  > * {
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
}

html {
  font-size: 14px;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-select-selector > *,
.ant-select-item-option-selected,
.ant-select-item-option-content,
.ant-tooltip-content {
  font-size: 10px;
}

.ant-select-item-option-selected {
  background-color: #eee !important;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: #ddd !important;
}

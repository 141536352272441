@import '../../styles.module.scss';

.common {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  width: 2rem;
  background-color: $colorBg;
  color: #333;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  user-select: none;

  svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: currentColor;
  }
}

.ctrBtn {
  @extend .common, .clickable;
  height: 2rem;
  font-size: 1rem;

  &.selected {
    color: $colorPrimary;
  }
}

.ctrBtnGroup {
  @extend .common;
  flex-direction: column;
  overflow: hidden;

  > * {
    border-radius: 0 !important;
  }
}

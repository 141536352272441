@import '../../styles.module.scss';

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .map {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .controls {
      position: absolute;
      top: 5rem;
      right: 0rem;
      margin: 0.5rem;
      z-index: 2;

      > * {
        margin-bottom: 0.5rem;
      }

      .compass {
        width: 2rem;
        height: 2rem;
      }
    }

    .loadingMask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(white, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
    }
  }

  .actionBar {
    position: absolute;
    top: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    background-color: rgba(white, 0.9);
    z-index: 5;

    img {
      height: 4rem;
    }

    .select {
      margin: 0 0.1rem;

      :global(.ant-select-selector) {
        background-color: #6c757d;
        height: 2.5rem;
      }

      :global(.ant-select-selection-placeholder),
      :global(.ant-select-selection-item) {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: white;
        font-weight: 500;

        svg {
          fill: white !important;
          height: 1.2rem;
          width: 1.2rem;
          margin-right: 0.2rem;
        }
      }

      :global(.ant-select-arrow) {
        color: white;
      }
    }
  }

  .whiteBorderedText {
    text-shadow:
      -1px -1px 0 #fffa,
      1px -1px 0 #fffa,
      -1px 1px 0 #fffa,
      1px 1px 0 #fffa;
  }

  .dataControl {
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 30rem;
    max-width: 50rem;
    width: calc(50% - 5rem);
    padding: 1rem 2rem;
    margin: 1rem;
    border-radius: 0.25rem;
    background-color: rgba($colorBg, 0.8);
    z-index: 2;

    .chartArea {
      position: relative;
      display: flex;
      flex-direction: column;

      .close {
        @extend.clickable;
        position: absolute;
        top: 0rem;
        left: 0rem;
        width: 1rem;
        height: 1rem;
        padding: 0.5rem;
        border-radius: 50%;
        margin-left: -1rem;

        &:hover {
          background-color: rgba($colorBg, 0.5);
        }
      }

      .stationType {
        border-radius: 0.25rem;
        overflow: hidden;
        align-self: flex-end;
        color: $colorDefault;
        display: flex;

        > * {
          @extend .clickable;
          padding: 0rem 1rem;
          background-color: rgba(#777, 0.4);
        }

        .full {
          background-color: rgba(#777, 0.8);
        }
      }

      .title {
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100%;
        z-index: 15;

        .stationName {
          @extend .whiteBorderedText, .clickable;
          min-width: 100%;
          text-align: center;
          font-size: 1.25rem;
        }

        .element {
          font-size: 1rem;
          opacity: 0.5;
        }
      }

      .chart {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .noData {
        margin-top: 5rem;
        margin-bottom: 2rem;
        padding: 3rem 1rem;
        text-align: center;
        border-radius: 0.5rem;
        background: repeating-linear-gradient(
          45deg,
          #ddd5,
          #ddd5 0.25rem,
          #aaa5 0.25rem,
          #aaa5 0.5rem
        );
      }
    }

    .date {
      @extend .whiteBorderedText;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .slider {
    }

    .controls {
      display: flex;
      margin-top: 1rem;

      > * {
        height: 2rem;
        margin-right: 0.25rem;
        display: flex;
        align-items: center;
      }

      .btn {
        label {
          margin-left: 0.5rem;
        }
      }

      .downloader {
        margin-left: -10rem;
        opacity: 0 !important;
        z-index: -1;
        width: 10rem;
      }
    }
  }
}
